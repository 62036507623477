import React from 'react'
import get from 'lodash/get'

import ScrollListener from '@util/scroll_listener'
import { withViewport } from '@util/viewport'

const bluePattern = '/static/images/backgrounds/teal.svg'

class BackgroundColorChanger extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundColor: 'white',
    }
    this.childBlocks = []
  }

  componentDidMount() {
    this.rootEl = document.querySelector('.rootWrapper')
    this.rootBgEl = document.querySelector('.rootWrapper__bg')
    this.rootEl.style.backgroundColor = 'white'
    this.computeColorStops()

    const defaultColor = get(this, 'colorStops[0].color') || 'white'
    this.setBodyClasses(defaultColor)
  }

  setColorStop = stop => {
    this.currentColorStopName = stop.colorName

    this.rootEl.style.backgroundColor = stop.color
    if (stop.colorName === 'bluePattern') {
      const bg = `url(${bluePattern})`
      this.rootBgEl.style.backgroundImage = bg
      this.rootBgEl.style.backgroundRepeat = 'repeat'
      this.rootBgEl.style.backgroundSize = '968px 1056px'
      this.rootBgEl.style.opacity = '1'
    } else {
      this.rootEl.style.backgroundColor = stop.color
      this.rootBgEl.style.opacity = '0'
    }
  }

  getCurrentColorStop = scrollY => {
    const defaultColor = get(this, 'colorStops[0].color') || 'white'
    const windowHeight = this.props.viewport.height
    if (scrollY < 400) return defaultColor
    for (let i = this.colorStops.length - 1; i >= 0; i--) {
      const stop = this.colorStops[i]

      if (stop.el.getBoundingClientRect().top - 0.6 * windowHeight <= 0) {
        return stop
      }
    }
    return defaultColor
  }

  onScroll = e => {
    const windowHeight = this.props.viewport.height
    const newStop = this.getCurrentColorStop(e.scrollY, windowHeight)

    if (newStop.colorName !== this.currentColorStopName) {
      this.setColorStop(newStop)
    }
    if (this.props.onScroll) {
      this.props.onScroll(e)
    }
  }

  computeColorStops = () => {
    this.colorStops = []
    this.childBlocks.forEach(block => {
      const colorStop = block.getColorStop()
      if (colorStop) {
        this.colorStops.push(colorStop)
      }
    })
  }

  addChildRef = el => {
    if (el && el.getColorStop) {
      this.childBlocks.push(el)
    }
  }

  setBodyClasses = color => {
    switch (color) {
      case '#FC9700':
        document.body.className = 'pageBg--dark pageBg--orange'
        break
      case '#D7F5F5':
        document.body.className = 'pageBg--blue'
        break
      case '#FFFFFF':
        document.body.className = 'pageBg--white'
        break
      case '#FAF5E6':
        document.body.className = 'pageBg--tan'
        break
      default:
        break
    }
  }

  render() {
    return (
      <ScrollListener onScroll={this.onScroll}>
        {this.props.children(this.addChildRef)}
      </ScrollListener>
    )
  }
}

export default withViewport(BackgroundColorChanger)
