import React from 'react'
import Plx from 'react-plx'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import BackgroundColorChanger from '@components/layout/background_color_changer'
import styles from '@components/layout/homepage_layout.module.scss'

const HomepageLayout = ({ children }) => {
  const isExtraLarge = useMediaQuery({ minWidth: 1160 })
  const foregroundDuration = isExtraLarge ? 1000 : 400

  return (
    <BackgroundColorChanger>
      {addChildRef => (
        <React.Fragment>
          {React.cloneElement(children[0], { ref: addChildRef })}
          <Plx
            className={classNames('page__foreground', styles.homepage__foreground)}
            parallaxData={[
              {
                start: 'self',
                offset: '33vh',
                duration: foregroundDuration,
                name: 'background',
                properties: [
                  {
                    startValue: 'rgba(255,255,255,1)',
                    endValue: 'rgba(255,255,255,0)',
                    property: 'backgroundColor',
                  },
                ],
              },
            ]}
          >
            {children.slice(1).map(child => React.cloneElement(child, { ref: addChildRef }))}
          </Plx>
        </React.Fragment>
      )}
    </BackgroundColorChanger>
  )
}

export default HomepageLayout
